<template>
    <div class="add_box">
        <el-form
            ref="form"
            style="
                flex: 1;
                margin-top: 20px;
                margin-left: 40px;
                margin-bottom: 80px;
            "
            label-position="left"
            label-width="80px"
        >
            <el-form-item label="产品类别" required>
                <!-- <el-cascader
                    :options="startDataSelect"
                    :props="{ checkStrictly: true }"
                    clearable
                    :show-all-levels="false"
                >
                    <template slot-scope="{ node, data }">
                        <span>{{ data.productName }}</span>
                    </template>
                </el-cascader> -->
                <el-select
                    v-model="id"
                    placeholder="请选择产品类别"
                    size="small"
                    style="width: 240px"
                >
                    <el-option
                        v-for="item in startDataSelect"
                        :key="item.id"
                        :label="item.productName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-button
                    v-if="isEdit && data.status == 1"
                    type="primary"
                    style="
                        background: #fff;
                        border-color: #d0021b;
                        color: #d0021b;
                        margin-left: 50px;
                    "
                    size="mini"
                    @click="disabledClassify(data)"
                    >禁用</el-button
                >
                <el-button
                    v-if="isEdit && data.status == 0"
                    type="primary"
                    style="
                        background: #fff;
                        border-color: #2370eb;
                        color: #2370eb;
                        margin-left: 50px;
                    "
                    size="mini"
                    @click="startClassify(data)"
                    >启用</el-button
                >
            </el-form-item>
            <el-form-item label="产品名称" required>
                <el-input
                    v-model="form.productName"
                    size="small"
                    style="width: 240px"
                    placeholder="请输入产品名称"
                ></el-input>
            </el-form-item>
            <el-form-item label="产品备注">
                <el-input
                    v-model="form.remark"
                    size="small"
                    type="textarea"
                    rows="3"
                    style="width: 240px"
                    placeholder="请输入产品备注"
                ></el-input>
            </el-form-item>
            <div>
                <el-form-item label="产品属性"> </el-form-item>
                <div class="attribute">
                    <div
                        class="buy_type"
                        v-for="(item, index) in productAttributeRelationDOList"
                        :key="index"
                    >
                        <el-button
                            v-if="index > 0"
                            type="primary"
                            class="el-icon-top ordinary"
                            circle
                            @click.stop="toTop(index)"
                            size="mini"
                        ></el-button>
                        <p class="title" v-if="item">
                            <span>{{ item.attributeName || '- -' }}</span>
                            <el-checkbox
                                v-if="
                                    item.productAttributeValueBOList &&
                                    item.productAttributeValueBOList.length > 0
                                "
                                :indeterminate="item.isIndeterminate"
                                v-model="item.checkAll"
                                @change="
                                    (val) => {
                                        handleCheckAllChange(val, item);
                                    }
                                "
                            ></el-checkbox>
                            <el-checkbox
                                v-else
                                :disabled="item.attributeName == '应收金额'"
                                v-model="item.checkAll"
                                @change="
                                    (val) => {
                                        handleCheckAllChange(val, item);
                                    }
                                "
                            ></el-checkbox>
                        </p>
                        <p
                            class="aaaa"
                            v-if="
                                item.productAttributeValueBOList &&
                                item.productAttributeValueBOList.length > 0 &&
                                item.attributeName != '版本'
                            "
                        >
                            <el-checkbox-group
                                :style="{
                                    justifyContent:
                                        item.attributeName == '版本'
                                            ? 'flex-start'
                                            : 'space-between',
                                }"
                                v-model="item.checkedAttribute"
                                @change="
                                    (val) => {
                                        handleCheckedAttributeChange(val, item);
                                    }
                                "
                            >
                                <el-checkbox
                                    :style="{
                                        width:
                                            item.attributeName == '版本'
                                                ? '48%'
                                                : '30%',
                                        marginRight:
                                            idx % 2 == 0 &&
                                            item.attributeName == '版本'
                                                ? '10px'
                                                : '',
                                        marginBottom:
                                            item.attributeName == '版本'
                                                ? '10px'
                                                : '',
                                    }"
                                    v-for="(
                                        itm, idx
                                    ) in item.productAttributeValueBOList"
                                    :label="itm.id"
                                    :key="itm.id"
                                    >{{ itm.attributeValueName }}</el-checkbox
                                >
                            </el-checkbox-group>
                            <!-- <span
                                >购买<el-checkbox
                                    v-model="checked"
                                ></el-checkbox
                            ></span>
                            <span
                                >续费<el-checkbox
                                    v-model="checked"
                                ></el-checkbox
                            ></span>
                            <span
                                >增购<el-checkbox
                                    v-model="checked"
                                ></el-checkbox
                            ></span>
                        </p> -->
                        </p>
                        <div
                            class="aaaa"
                            v-if="
                                item.productAttributeValueBOList &&
                                item.productAttributeValueBOList.length > 0 &&
                                item.attributeName == '版本'
                            "
                        >
                            <el-checkbox-group
                                :style="{
                                    justifyContent: 'flex-start',
                                }"
                                v-model="item.checkedAttribute"
                                @change="
                                    (val) => {
                                        handleCheckedAttributeChange(val, item);
                                    }
                                "
                            >
                                <el-checkbox
                                    v-if="itm.attributeType == 1"
                                    :style="{
                                        width: '48%',
                                        marginRight: idx % 2 == 0 ? '10px' : '',
                                        marginBottom: '10px',
                                    }"
                                    v-for="(
                                        itm, idx
                                    ) in item.productAttributeValueBOList"
                                    :label="itm.id"
                                    :key="itm.id"
                                    >{{ itm.attributeValueName }}
                                </el-checkbox>
                                <p>
                                    <!--  -->
                                    <!--  -->
                                    <el-checkbox
                                        v-if="itm.attributeType == 2"
                                        :style="{
                                            width: '48%',
                                            marginRight:
                                                idx % 2 == 0 ? '10px' : '',
                                            marginBottom: '10px',
                                        }"
                                        v-for="(
                                            itm, idx
                                        ) in item.productAttributeValueBOList"
                                        :label="itm.id"
                                        :key="itm.id"
                                        >{{
                                            itm.attributeValueName
                                        }}</el-checkbox
                                    >
                                </p>
                                <p>
                                    <!--  -->
                                    <!--  -->

                                    <el-checkbox
                                        v-if="itm.attributeType == 3"
                                        :style="{
                                            width: '48%',
                                            marginRight:
                                                itm.attributeValueName ==
                                                'VIP账户服务版'
                                                    ? '10px'
                                                    : '',
                                            marginBottom: '10px',
                                        }"
                                        v-for="(
                                            itm, idx
                                        ) in item.productAttributeValueBOList"
                                        :label="itm.id"
                                        :key="itm.id"
                                        >{{
                                            itm.attributeValueName
                                        }}</el-checkbox
                                    >
                                </p>
                                <p>
                                    <!--  -->
                                    <!--  -->
                                    <el-checkbox
                                        v-if="itm.attributeType == 4"
                                        :style="{
                                            width: '48%',
                                            marginRight:
                                                itm.attributeValueName ==
                                                'Hmail旗舰版'
                                                    ? '10px'
                                                    : '',
                                            marginBottom: '10px',
                                        }"
                                        v-for="(
                                            itm, idx
                                        ) in item.productAttributeValueBOList"
                                        :label="itm.id"
                                        :key="itm.id"
                                        >{{
                                            itm.attributeValueName
                                        }}</el-checkbox
                                    >
                                </p>
                            </el-checkbox-group>
                            <!-- <span
                                >购买<el-checkbox
                                    v-model="checked"
                                ></el-checkbox
                            ></span>
                            <span
                                >续费<el-checkbox
                                    v-model="checked"
                                ></el-checkbox
                            ></span>
                            <span
                                >增购<el-checkbox
                                    v-model="checked"
                                ></el-checkbox
                            ></span>
                        </p> -->
                        </div>
                    </div>

                    <!-- <div class="ordinary_box">
                        <el-button
                            type="primary"
                            class="el-icon-top ordinary"
                            circle
                            @click.stop="toTop()"
                            size="mini"
                        ></el-button>
                        <p class="title">
                            <span>用户数</span
                            ><el-checkbox v-model="checkAll"></el-checkbox>
                        </p>
                    </div>
                    <div class="ordinary_box">
                        <el-button
                            type="primary"
                            class="el-icon-top ordinary"
                            circle
                            @click.stop="toTop()"
                            size="mini"
                        ></el-button>
                        <p class="title">
                            <span>年限</span
                            ><el-checkbox v-model="checkAll"></el-checkbox>
                        </p>
                    </div>
                    <div class="ordinary_box">
                        <el-button
                            type="primary"
                            class="el-icon-top ordinary"
                            circle
                            @click.stop="toTop()"
                            size="mini"
                        ></el-button>
                        <p class="title">
                            <span>应收金额</span
                            ><el-checkbox v-model="checkAll"></el-checkbox>
                        </p>
                    </div> -->
                </div>
            </div>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 16px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>

<script>
import {
    productAttributeList,
    productAdd,
    productList,
    productAttributeRelationList,
    productUpdate,
} from '@/api/product/product.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {
                remark: '',
                productName: '',
            },
            productAttributeRelationDOList: [],
            startDataSelect: [],
            id: '',
            data: {},
            isEdit: false,
        };
    },
    components: {},
    created() {},
    mounted() {},
    methods: {
        getData() {
            this.form = {
                remark: '',
                productName: '',
            };
            this.id = '';
            this.isEdit = false;
            this.productAttributeList();
            this.productList();
        },
        getEditData(data) {
            this.isEdit = true;
            this.data = data;
            this.form.productName = data.productName;
            this.form.remark = data.remark;
            this.id = data.parentId;
            this.productAttributeList();
            this.productList();
        },
        productAttributeRelationList() {
            let data = {
                param: {
                    productId: this.data.id,
                },
            };
            productAttributeRelationList(data).then((res) => {
                if (res.code == 200) {
                    let obj = {};
                    this.productAttributeRelationDOList.forEach(
                        (item, index) => {
                            res.data.list.forEach((itm) => {
                                if (item.id == itm.attributeId) {
                                    item.reorder = itm.reorder;
                                    this.productAttributeRelationDOList[
                                        index
                                    ].checkAll =
                                        this.productAttributeRelationDOList[
                                            index
                                        ].productAttributeValueBOList.length ===
                                        itm.productAttributeValueBOList.length;

                                    this.productAttributeRelationDOList[
                                        index
                                    ].checkedAttribute = itm.productAttributeValueBOList.map(
                                        (itm) => {
                                            return itm.id;
                                        }
                                    );
                                } else {
                                }
                            });

                            if (
                                this.productAttributeRelationDOList[index]
                                    .checkedAttribute &&
                                this.productAttributeRelationDOList[index]
                                    .checkedAttribute.length > 0
                            ) {
                                item.isIndeterminate = true;
                            }
                        }
                    );

                    for (
                        var i = 0;
                        i < this.productAttributeRelationDOList.length;
                        i++
                    ) {
                        for (
                            var j = i + 1;
                            j < this.productAttributeRelationDOList.length;
                            j++
                        ) {
                            //如果第一个比第二个大，就交换他们两个位置
                            if (
                                this.productAttributeRelationDOList[i]
                                    .reorder &&
                                this.productAttributeRelationDOList[j]
                                    .reorder &&
                                this.productAttributeRelationDOList[i].reorder >
                                    this.productAttributeRelationDOList[j]
                                        .reorder
                            ) {
                                var temp =
                                    this.productAttributeRelationDOList[i];
                                this.productAttributeRelationDOList[i] =
                                    this.productAttributeRelationDOList[j];
                                this.productAttributeRelationDOList[j] = temp;
                            } else {
                                console.log(
                                    this.productAttributeRelationDOList[i]
                                );
                            }
                        }
                    }
                    // this.productAttributeRelationDOList.push(obj);
                    // console.log(this.productAttributeRelationDOList);
                }
            });
        },
        productList() {
            let data = {
                param: {
                    status: 1,
                    type: 1,
                },
                pageSize: 0,
                pageNum: 0,
            };
            productList(data).then((res) => {
                // let dataArr = this.$Tree.treeDataTranslateAudit(
                //     res.data.list,
                //     'id',
                //     'parentId'
                // );
                // dataArr.sort((a, b) => {
                //     return a.reorder - b.reorder;
                // });
                this.startDataSelect = res.data.list;
            });
        },
        productAttributeList() {
            let data = {
                param: {},
            };
            productAttributeList(data).then((res) => {
                if (res.code == 200) {
                    console.log(res);
                    res.data.list.forEach((item) => {
                        if (item.attributeName == '应收金额') {
                            item.checkAll = true;
                        } else {
                            item.checkAll = false;
                        }

                        item.checkedAttribute = [];
                        item.isIndeterminate = false;
                    });
                    this.productAttributeRelationDOList = res.data.list;
                    if (this.isEdit) {
                        this.productAttributeRelationList();
                    }
                }
            });
        },
        handleCheckAllChange(val, item) {
            item.checkedAttribute = val
                ? item.productAttributeValueBOList.map((itm) => {
                      return itm.id;
                  })
                : [];
            item.isIndeterminate = false;
        },
        handleCheckedAttributeChange(value, item) {
            let checkedCount = value.length;
            item.checkAll =
                checkedCount === item.productAttributeValueBOList.length;
            item.isIndeterminate =
                checkedCount > 0 &&
                checkedCount < item.productAttributeValueBOList.length;
        },
        toTop(index) {
            this.productAttributeRelationDOList = this.swopItems(
                this.productAttributeRelationDOList,
                index
            );
        },
        swopItems(arr, index) {
            arr[index] = arr.splice(index - 1, 1, arr[index])[0];
            return arr;
        },
        swopItems2(arr, index1, index2) {
            arr[index1] = arr.splice(index2, 1, arr[index1])[0];
            return arr;
        },
        chachong() {
            let data = {
                param: {
                    parentId: this.id,
                    productName: this.form.productName,
                    type: 2,
                },
            };
            return productList(data);
        },
        async onSubmit() {
            let res = await this.chachong();
            if (res.data.total > 0 && !this.isEdit) {
                return this.$message.error('产品名称重复，请修改');
            }
            let data = {
                param: {
                    parentId: this.id,
                    productName: this.form.productName,
                    remark: this.form.remark,
                    type: 2,
                },
            };
            if (!this.id) {
                return this.$message.error('请选择产品类别');
            }
            if (!this.form.productName) {
                return this.$message.error('请输入产品名称');
            }

            let arr = [];
            // data.param.productAttributeRelationDOList =
            this.productAttributeRelationDOList.forEach((item, index) => {
                if (
                    (item.checkAll && item.checkedAttribute.length == 0) ||
                    (!item.checkAll && item.checkedAttribute.length > 0)
                ) {
                    arr.push({
                        attributeId: item.id,
                        reorder: index + 1,
                    });
                }
                if (item.checkedAttribute && item.checkedAttribute.length > 0) {
                    item.checkedAttribute.forEach((itm) => {
                        arr.push({
                            attributeId: item.id,
                            attributeValueId:
                                item.productAttributeValueBOList.filter((i) => {
                                    return i.id == itm;
                                })[0].id,
                            attributeValue:
                                item.productAttributeValueBOList.filter((i) => {
                                    return i.id == itm;
                                })[0].attributeValue,
                            reorder: index + 1,
                        });
                        // console.log(
                        //     item.productAttributeValueBOList.filter((i) => {
                        //         return i.id == itm;
                        //     })
                        // );
                    });
                }
            });
            for (var i = 0; i < arr.length; i++) {
                for (var j = i + 1; j < arr.length; j++) {
                    if (
                        arr[i].attributeId == arr[j].attributeId &&
                        arr[i].attributeValue == arr[j].attributeValue
                    ) {
                        return this.$message.error('版本配置错误，请重新配置');
                    }
                }
            }

            data.param.productAttributeRelationDOList = arr;

            if (this.isEdit) {
                data.param.id = this.data.id;
                productUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.onClose();
                    }
                });
            } else {
                productAdd(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.onClose();
                    }
                });
            }
        },
        disabledClassify(item) {
            this.$confirm('是否确定禁用当前产品？', '提示', {
                confirmButtonText: '确定',
                confirmButtonClass: 'pri',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = {
                        param: {
                            id: item.id,
                            status: 0,
                        },
                    };
                    productUpdate(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('操作成功');
                            this.onClose();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消禁用',
                    });
                });
        },
        startClassify(item) {
            this.$confirm('是否确定启用当前产品？', '提示', {
                confirmButtonText: '确定',
                confirmButtonClass: 'pri',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = {
                        param: {
                            id: item.id,
                            status: 1,
                        },
                    };
                    productUpdate(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('操作成功');
                            this.onClose();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消启用',
                    });
                });
        },
        onClose() {
            this.$emit('close', this.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.add_box {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: #fff;
    box-sizing: border-box;
    overflow-y: auto;

    /deep/ .el-textarea__inner {
        resize: none;
    }
    .contacts_form_title {
        font-size: 14px;
        margin-bottom: 15px;
        color: #333;
        font-weight: 600;
        position: relative;
    }
    .contacts_form_title:before {
        content: '';
        position: absolute;
        left: -8px;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #2370eb;
    }
    /deep/ .el-form-item {
        margin: 8px !important;
    }
    .attribute {
        padding-right: 50px;
        padding-left: 30px;
        .title {
            background-color: #f0f5fd;
            font-size: 13px;
            padding: 7px 14px;
            display: flex;
            margin-top: 12px;
            span {
                flex: 1;
            }
            .el-checkbox {
            }
        }
        .buy_type {
            position: relative;
            .aaaa {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .el-checkbox-group {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    > label {
                        width: 30%;
                        height: 25px;
                        line-height: 25px;
                        margin-right: 0;
                        border: 1px solid #e5e5e5;
                        /deep/ .el-checkbox__label {
                            float: left !important;
                            margin-top: 3px;
                            font-size: 12px;
                        }
                        /deep/ .el-checkbox__input {
                            float: right !important;
                            margin-top: 5.5px;
                            margin-right: 10px;
                        }
                    }
                    p {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        width: 100%;
                        > label {
                            width: 30%;
                            height: 25px;
                            line-height: 25px;
                            margin-right: 0;
                            border: 1px solid #e5e5e5;
                            /deep/ .el-checkbox__label {
                                float: left !important;
                                margin-top: 3px;
                                font-size: 12px;
                            }
                            /deep/ .el-checkbox__input {
                                float: right !important;
                                margin-top: 5.5px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
                span {
                    display: block;
                    height: 32px;
                    width: 120px;
                    line-height: 32px;
                    font-size: 13px;
                    box-sizing: border-box;
                    padding: 0 10px;
                    background: #ffffff;
                    border-radius: 4px;
                    border: 1px solid #e5e5e5;
                }
                .el-checkbox {
                    float: right;
                }
            }
        }
    }
    .ordinary_box {
        position: relative;
    }
}
.bottom-but {
    height: 48px;
    line-height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e5e5e5;
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    width: 20px;
    height: 20px;
    border: 2px solid #2370eb;
    margin-left: 5px;
}
.el-button--mini.is-circle.ordinary {
    background: #fff;
    color: #2370eb;
    border: 2px solid #2370eb;
    margin-left: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    position: absolute;
    left: -20px;
    top: 8px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
}
</style>

<template>
    <div class="department">
        <div>
            <p class="title">
                <img src="@/img/shangjia.png" alt="" />
                <span>上架产品</span>
                <el-dropdown trigger="click" v-if="btnP.classify">
                    <span
                        class="el-dropdown-link"
                        style="color: #111; cursor: pointer"
                    >
                        <i class="el-icon-plus"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <el-button
                                type="text"
                                size="mini"
                                @click="addClassify('')"
                                style="color: #353535; font-size: 12px"
                                >新建子分类</el-button
                            >
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </p>
            <div class="tree_box">
                <div
                    class="first_layer"
                    v-for="(item, index) in startData"
                    :key="item.id"
                    v-if="item.parentId == 0"
                >
                    <h2
                        @click.prevent="changeActive(item, index)"
                        :class="active === index ? 'active' : ''"
                        @mouseover="
                            () => {
                                item.isShow = true;
                            }
                        "
                        @mouseleave="
                            () => {
                                item.isShow = false;
                            }
                        "
                    >
                        <el-button
                            v-if="index > 0 && btnP.classify && item.isShow"
                            type="primary"
                            class="el-icon-top ordinary"
                            circle
                            @click.stop="toTop(index)"
                            size="mini"
                        ></el-button>
                        <span>{{ item.productName }}</span>
                        <el-dropdown trigger="click" v-if="btnP.classify">
                            <span
                                class="el-dropdown-link"
                                style="color: #111; cursor: pointer"
                            >
                                <i class="el-icon-more"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        size="mini"
                                        @click="addClassify(item)"
                                        style="color: #353535; font-size: 12px"
                                        >新建子分类</el-button
                                    >
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        size="mini"
                                        @click="updateClassify('', item)"
                                        style="color: #353535; font-size: 12px"
                                        >修改分类名称</el-button
                                    >
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        size="mini"
                                        @click="disabledClassify(item)"
                                        style="color: #353535; font-size: 12px"
                                        >禁用分类</el-button
                                    >
                                </el-dropdown-item>

                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        size="mini"
                                        @click="deleteClassify(item)"
                                        style="color: #353535; font-size: 12px"
                                        >删除分类</el-button
                                    >
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </h2>
                    <div
                        class="last_layer"
                        v-if="item.children && item.children.length > 0"
                    >
                        <p
                            v-for="(itm, idx) in item.children"
                            :key="itm.id"
                            @click="getItmData(itm, idx)"
                            :class="
                                itmActive === idx && itemParentId == item.id
                                    ? 'active'
                                    : ''
                            "
                        >
                            <i></i> <span>{{ itm.productName }}</span>
                            <el-dropdown trigger="click" v-if="btnP.classify">
                                <span
                                    class="el-dropdown-link"
                                    style="color: #111; cursor: pointer"
                                >
                                    <i class="el-icon-more"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>
                                        <el-button
                                            type="text"
                                            size="mini"
                                            @click="updateClassify(item, itm)"
                                            style="
                                                color: #353535;
                                                font-size: 12px;
                                            "
                                            >修改分类名称</el-button
                                        >
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <el-button
                                            type="text"
                                            size="mini"
                                            @click="disabledClassify(itm)"
                                            style="
                                                color: #353535;
                                                font-size: 12px;
                                            "
                                            >禁用分类</el-button
                                        >
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <el-button
                                            type="text"
                                            size="mini"
                                            @click="deleteClassify(itm)"
                                            style="
                                                color: #353535;
                                                font-size: 12px;
                                            "
                                            >删除分类</el-button
                                        >
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <p
                :class="disActive === 0 ? 'active title' : 'title'"
                style="cursor: pointer"
                @click="changeDisActive({}, -1)"
            >
                <img src="@/img/xiajia.png" alt="" />
                <span>下架产品</span>
            </p>
            <div class="tree_box xiajia">
                <div
                    class="first_layer"
                    v-for="(item, index) in disabledData"
                    :key="item.id"
                >
                    <h2
                        :class="disActive === index + 1 ? 'active' : ''"
                        @click="changeDisActive(item, index)"
                    >
                        <span>{{ item.productName }}</span>
                        <el-switch
                            v-if="btnP.classify"
                            v-model="item.check"
                            @change="startClassify(item)"
                            size="small"
                        >
                        </el-switch>
                        <i
                            v-if="btnP.classify"
                            class="el-icon-delete-solid"
                            @click="deleteClassify(item)"
                        ></i>
                    </h2>
                    <!-- <div
                        class="last_layer"
                        v-if="item.children && item.children.length > 0"
                    >
                        <p v-for="(itm, idx) in item.children" :key="itm.id">
                            <i></i> <span>{{ itm.productName }}</span>
                            <el-switch
                                v-model="itm.status"
                                @change="startClassify(itm)"
                                size="small"
                            >
                            </el-switch>
                            <i
                                class="el-icon-delete-solid"
                                @click="deleteClassify"
                            ></i>
                        </p>
                    </div> -->
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="dialogAdd"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增子分类
                </div>
            </template>
            <Add @close="handleClose" ref="add" />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogUpdate"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    修改子分类
                </div>
            </template>
            <Add @close="handleClose" ref="update" />
        </el-dialog>
    </div>
</template>
<script>
import {
    productList,
    productDelete,
    productUpdate,
} from '@/api/product/product.js';
import Add from '../add/classify.vue';
export default {
    data() {
        return {
            id: '',
            data: [],
            list: '',
            dialogAdd: false,
            dialogUpdate: false,
            dempName: '',
            active: 0,
            btnP: {},
            isShow: false,
            startData: [],
            disabledData: [],
            itmActive: '',
            itemParentId: '',
            itm: {},
            disActive: 0,
        };
    },
    components: {
        Add,
    },
    mounted() {},
    methods: {
        changeActive(item, index) {
            this.itmActive = '';
            this.disActive = '';
            this.active = index;
            this.$emit('getStartProduct', item.id);
        },
        changeDisActive(item, index) {
            this.disActive = index + 1;
            this.itmActive = '';
            this.active = '';
            this.$emit('getDisProduct', item.id);
        },
        getItmData(itm, idx) {
            this.itemParentId = itm.parentId;
            // console.log(itm.parentId);
            this.itm = itm;
            this.disActive = '';
            this.active = '';
            this.itmActive = idx;
            this.$emit('getItmStartProduct', itm.id);
        },
        toTop(index) {
            console.log(index);
            // this.startData = this.swopItems(this.startData, index);
            let data = {
                param: {
                    reorder: index - 1,
                    id: this.startData[index].id,
                },
            };
            productUpdate(data).then((res) => {
                if (res.code == 200) {
                    let data = {
                        param: {
                            reorder: index,
                            id: this.startData[index - 1].id,
                        },
                    };
                    productUpdate(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('操作成功');
                            this.getData(this.btnP);
                        }
                    });
                }
            });
        },
        swopItems(arr, index) {
            arr[index] = arr.splice(index - 1, 1, arr[index])[0];
            return arr;
        },
        productList(status, type) {
            let data = {
                param: {
                    status,
                    type,
                },
                pageSize: 0,
                pageNum: 0,
            };
            productList(data).then((res) => {
                if (status == 1) {
                    let dataArr = this.$Tree.treeDataTranslateAudit(
                        res.data.list,
                        'id',
                        'parentId'
                    );
                    dataArr.sort((a, b) => {
                        return a.reorder - b.reorder;
                    });
                    dataArr.forEach((item, index) => {
                        item.isShow = false;
                    });
                    // console.log(dataArr);
                    this.startData = dataArr;
                    if (this.active === '') {
                        this.getItmData(this.itm, this.itmActive);
                    } else {
                        this.changeActive(dataArr[0], 0);
                    }
                } else {
                    res.data.list.forEach((item) => {
                        item.check = false;
                    });
                    this.disabledData = res.data.list;
                }
            });
        },
        getData(btnP) {
            this.btnP = btnP;
            this.productList(1, 1);
            this.productList(0, 1);
        },
        addClassify(item) {
            console.log(item);
            this.dialogAdd = true;
            setTimeout(() => {
                this.$refs.add.getData(item);
            }, 0);
        },
        updateClassify(item, itm) {
            this.dialogUpdate = true;
            setTimeout(() => {
                this.$refs.update.getEditData(item, itm);
            }, 0);
        },
        disabledClassify(item) {
            this.$confirm('是否确定禁用当前分类？', '提示', {
                confirmButtonText: '确定',
                confirmButtonClass: 'pri',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = {
                        param: {
                            id: item.id,
                            status: 0,
                        },
                    };
                    productUpdate(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('操作成功');
                            this.getData(this.btnP);
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消禁用',
                    });
                });
        },
        startClassify(item) {
            this.$confirm('是否确定启用当前分类？', '提示', {
                confirmButtonText: '确定',
                confirmButtonClass: 'pri',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = {
                        param: {
                            id: item.id,
                            status: 1,
                        },
                    };
                    productUpdate(data).then((res) => {
                        if (res.code == 200) {
                            this.value1 = false;
                            this.$message.success('操作成功');
                            this.getData(this.btnP);
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消启用',
                    });
                    this.value1 = false;
                });
        },
        deleteClassify(item) {
            this.$confirm('是否确定删除当前分类？', '提示', {
                confirmButtonText: '确定',
                confirmButtonClass: 'pri',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = {
                        param: {
                            id: item.id,
                        },
                    };
                    productDelete(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('操作成功');
                            this.getData(this.btnP);
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },

        handleClose() {
            this.dialogAdd = false;
            this.dialogUpdate = false;
            this.getData(this.btnP);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.department {
    width: 100%;
    height: 100%;

    .title {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
        padding: 18px 12px;
        display: flex;
        align-items: center;
        > img {
            width: 12px;
            height: 12px;
            margin-right: 5px;
        }
        > span {
            flex: 1;
            font-size: 14px;
        }
        .el-icon-plus {
            border: 1px solid #4a84ff;
            padding: 2px;
            transform: scale(0.7);
            color: #4a84ff;
        }
    }
    .dep {
        width: 144px;
        height: 32px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 1px;
        box-sizing: border-box;
        padding-left: 14px;
        line-height: 32px;
        cursor: pointer;
        margin-left: 8px;
    }
    .dep + .dep {
        margin-top: 12px;
    }
    .dep.active {
        background: #f0f4ff;
        color: #4a84ff;
    }
}
.bottom-but {
    width: 100%;
    height: 48px;
    background: #fff;
    display: flex;
    padding: 0;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e5e5e5;
}
.el-icon-more {
    transform: rotateZ(90deg);
    color: #4a84ff;
}

.tree_box {
    .first_layer {
        padding: 0 8px;
        font-size: 13px;
        .el-switch {
            height: 15px !important;
        }
        /deep/ .el-switch__core {
            height: 15px !important;
            width: 30px !important;
        }
        /deep/ .el-switch__core:after {
            width: 11px;
            height: 11px;
        }
        /deep/ .el-switch.is-checked .el-switch__core::after {
            margin-left: -13px !important;
        }
        h2 {
            position: relative;
            height: 32px;
            line-height: 32px;
            font-size: 13px;
            padding-left: 25px;
            display: flex;
            align-items: center;
            padding-right: 6px;
            cursor: pointer;
            span {
                flex: 1;
            }
        }
        .active {
            background: #f0f4ff;
            border-radius: 1px;
            color: #2370eb;
        }
        .last_layer {
            p {
                cursor: pointer;
                position: relative;
                height: 32px;
                line-height: 32px;
                font-size: 13px;
                padding-left: 42px;
                display: flex;
                padding-right: 6px;
                align-items: center;
                span {
                    flex: 1;
                }
                > i:first-child {
                    position: absolute;
                    left: 28px;
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: #2370eb;
                }
            }
        }
    }
}
.active.title {
    background: #fff7f0;
    color: #da5120;
}
.xiajia {
    .first_layer {
        .last_layer {
            p {
                i:first-child {
                    background-color: #da5120;
                }
            }
        }
        .active {
            background: #fff7f0;
            color: #da5120;
        }
    }
}
.el-icon-delete-solid {
    color: #da5120;
    font-size: 16px;
    margin-left: 5px;
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    width: 20px;
    height: 20px;
    border: 2px solid #2370eb;
    margin-left: 5px;
}
.el-button--mini.is-circle.ordinary {
    background: #fff;
    color: #2370eb;
    border: 2px solid #2370eb;
    margin-left: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 5px;
    top: 50%;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    transform: translateY(-50%) scale(0.8);
}
</style>
<style lang="scss">
.el-message-box .pri {
    background: #2370eb !important;
    border-color: #2370eb;
}
</style>

<template>
    <div class="framework_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <img src="@/img/chanpin.png" alt="" />
                    <span>产品</span>
                </p>
                <p>
                    <el-button
                        @click="addProduct"
                        v-if="btnP.add"
                        type="primary"
                        size="small"
                    >
                        <i class="el-icon-plus"></i>
                        新增产品
                    </el-button>
                </p>
            </div>
        </div>
        <div class="framework">
            <div class="department-box">
                <Department
                    ref="department"
                    @getStartProduct="getStartProduct"
                    @getItmStartProduct="getItmStartProduct"
                    @getDisProduct="getDisProduct"
                />
            </div>
            <div class="user-box" ref="userbox">
                <Table ref="table" @onEdit="onEdit" />
            </div>
        </div>
        <el-drawer
            :visible.sync="drawerAdd"
            :direction="direction"
            :before-close="handleClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    新增产品
                </div>
            </template>
            <Add @close="handleClose" ref="add" />
        </el-drawer>
        <el-drawer
            :visible.sync="drawerUpdate"
            :direction="direction"
            :before-close="handleClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    编辑产品
                </div>
            </template>
            <Add @close="handleClose" ref="update" />
        </el-drawer>
    </div>
</template>
<script>
import Department from '../department/department.vue';
import Table from '../table/table.vue';
import Add from '../add/add.vue';
export default {
    components: {
        Department,
        Table,
        Add,
    },
    data() {
        return {
            id: '',
            name: '',
            departmentName: '',
            btnP: {},
            drawerAdd: false,
            drawerUpdate: false,
            direction: 'rtl',
        };
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;

            this.getTableData();
        },
        getTableData() {
            setTimeout(() => {
                this.$refs.department.getData(this.btnP);
            }, 0);
        },
        getStartProduct(parentId) {
            setTimeout(() => {
                this.$refs.table.getData(parentId, 1, 2, this.btnP);
            });
        },
        getItmStartProduct(parentId) {
            setTimeout(() => {
                this.$refs.table.getData(parentId, 1, 2, this.btnP);
            });
        },
        getDisProduct(parentId) {
            setTimeout(() => {
                this.$refs.table.getData(parentId, 0, 2, this.btnP);
            });
        },
        addProduct() {
            this.drawerAdd = true;
            setTimeout(() => {
                this.$refs.add.getData();
            }, 0);
        },
        onEdit(data) {
            this.drawerUpdate = true;
            setTimeout(() => {
                this.$refs.update.getEditData(data);
            }, 0);
        },
        handleClose(id) {
            this.drawerAdd = false;
            this.drawerUpdate = false;
            if (
                typeof id == 'number' &&
                this.$refs.department.$data.startData.findIndex((item) => {
                    return item.id == id;
                }) != -1 &&
                this.$refs.department.$data.disabledData.findIndex((item) => {
                    return item.id == id;
                }) == -1
            ) {
                console.log(456);
                this.$refs.department.$data.active =
                    this.$refs.department.$data.startData.findIndex((item) => {
                        return item.id == id;
                    });

                this.getStartProduct(id);
                return;
            } else if (
                typeof id == 'number' &&
                this.$refs.department.$data.startData.findIndex((item) => {
                    return item.id == id;
                }) == -1 &&
                this.$refs.department.$data.disabledData.findIndex((item) => {
                    return item.id == id;
                }) == -1
            ) {
                console.log(123);
                let data = '',
                    idx = 0;

                this.$refs.department.$data.startData.forEach((item) => {
                    if (item.children) {
                        item.children.forEach((itm, idx) => {
                            if (itm.id == id) {
                                data = itm;
                                idx = idx;
                            }
                        });
                    }
                });
                this.$refs.department.getItmData(data, idx);
                return;
                // this.getStartProduct(id);
            } else if (
                typeof id == 'number' &&
                this.$refs.department.$data.disActive !== ''
            ) {
                console.log(this.$refs.department.$data.disActive);
                if (this.$refs.department.$data.disActive) {
                    let data = '',
                        idx = 0;

                    this.$refs.department.$data.disabledData.forEach(
                        (item, index) => {
                            if (item.id == id) {
                                data = item;
                                idx = index;
                            }
                        }
                    );
                    this.$refs.department.changeDisActive(data, idx);
                } else {
                    this.$refs.department.changeDisActive({}, -1);
                }
                return;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.framework_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 15px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                display: flex;
                align-items: center;
                padding-left: 16px;
                span {
                    margin-left: 5px;
                }
            }
            button {
                background: #fff;
                border-radius: 2px;
                border-color: #2370eb;
                color: #2370eb;
                padding: 5px 15px;
                margin-right: 12px;
            }
        }
    }
    .framework {
        width: 100%;
        height: 100%;
        display: flex;
        .department-box {
            width: 200px;
            height: 100%;
            background: #fff;
            margin-right: 12px;
        }
        .user-box {
            width: calc(100% - 212px);
            background: #fff;
        }
    }
}
</style>

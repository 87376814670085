<template>
    <div class="member">
        <div class="form">
            <el-table
                ref="multipleTable"
                :data="tableData"
                stripe
                tooltip-effect="dark"
                class="customer-table"
                :row-style="{ height: '3.4rem' }"
                :cell-style="{ padding: '0px' }"
                height="100%"
                :header-cell-style="{
                    background: '#F9F9F9',
                    color: '#353535',
                    'font-size': '0.8rem',
                    'font-weight': 'bold',
                }"
            >
                <el-table-column
                    prop="productName"
                    label="产品名称"
                    show-overflow-tooltip
                    width="120"
                >
                    <template slot-scope="scope">
                        <div>
                            <el-button
                                v-if="btnP.details"
                                type="text"
                                style="color: #2370eb"
                                @click="onEdit(scope.row)"
                                >{{ scope.row.productName }}</el-button
                            >
                            <span v-else>{{ scope.row.productName }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    prop="productNo"
                    label="产品编号"
                    show-overflow-tooltip
                    width="120"
                >
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.productNo || '- -' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    label="产品分类"
                    width="120"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.parentName || '- -' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    prop="remark"
                    label="产品备注"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.remark || '- -' }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                layout="total,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import { productList } from '@/api/product/product.js';
export default {
    components: {},
    data() {
        return {
            tableData: [],
            dempId: '',
            currentPage: 1,
            pagesize: 20,
            total: 0,

            postDemp: '',
            postEidt: '',
            depmName: '',
            btnP: {},
            name: '',
        };
    },
    watch: {},
    mounted() {},
    methods: {
        // 点击获取成员
        getData(parentId, status, type, btnP) {
            this.btnP = btnP;
            let data = {
                param: { parentId, status, type },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };

            productList(data).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }
            });
        },
        // 上、下一页
        handleSizeChange(val) {
            this.pagesize = val;
            this.getuserList(this.dempId);
        }, // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getuserList(this.dempId);
        },
        // 禁用启用
        handleDelete(row) {
            let data = {
                param: {
                    adminIdList: [row.id],
                },
            };
            if (row.status == 1) {
                data.param.status = 0;
                this.$confirm('禁用后当前账号不可登录，是否禁用', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        statusUser(data).then((res) => {
                            if (res.code == 200) {
                                this.getuserList(this.dempId);
                                this.$message.success('禁用成功');
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消禁用',
                        });
                    });
            } else {
                data.param.status = 1;
                statusUser(data).then((res) => {
                    if (res.code == 200) {
                        this.getuserList(this.dempId);
                        this.$message.success('启用成功');
                    }
                });
            }
        },
        onEdit(row) {
            this.$emit('onEdit', row);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.member {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
        height: 50px;
        line-height: 50px;
        display: flex;
        align-items: center;
        padding: 0 20px;

        p {
            flex: 1;
            font-size: 14px;
            font-weight: 600;
        }
    }
    .form {
        flex: 1;
    }
    .page {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
}
</style>

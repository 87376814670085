<template>
    <div class="classify_box">
        <el-form label-width="100px" size="small">
            <el-form-item label="上级分类名称" required>
                <el-input
                    disabled
                    v-model="data.productName"
                    placeholder="请输入分类名称"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="产品分类名称" required>
                <el-input
                    v-model="form.productName"
                    placeholder="请输入分类名称"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 16px;
                "
                size="mini"
                @click="onSubmit"
                >保存</el-button
            >
        </div>
    </div>
</template>

<script>
import { productAdd, productUpdate } from '@/api/product/product.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {
                productName: '',
            },
            isEdit: false,
            data: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getEditData(data, form) {
            this.data = data || { productName: '上架产品' };
            this.isEdit = true;
            this.form.id = form.id;
            this.form.productName = form.productName;
        },
        getData(data) {
            this.data = data || { productName: '上架产品' };
            this.isEdit = false;
            this.form = {
                productName: '',
            };
        },
        onSubmit() {
            let data = {
                param: {
                    parentId: this.data.id,
                    productName: this.form.productName,
                    type: 1,
                },
            };
            if (!this.form.productName) {
                return this.$message.error('请输入分类名称');
            }
            if (this.isEdit) {
                data.param.id = this.form.id;
                productUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.onClose();
                    }
                });
            } else {
                productAdd(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.onClose();
                    }
                });
            }
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.classify_box {
    position: relative;
    .el-form {
        padding: 32px 0 150px 80px;
    }
}
.but {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
